<template>
  <div
    class="align-start text-left overflow-hidden transform transition-all w-full"
  >
    <patient-form :patient="$route.params.user_id" />
    <!-- notification -->
    <transition name="fade" mode="out-in">
      <div v-if="success" class="w-full flex justify-end items-center">
        <div
          class="mb-4 w-full max-w-sm overflow-hidden rounded-lg bg-white border border-gray-200"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <!-- Heroicon name: outline/check-circle -->
                <svg
                  class="h-6 w-6 text-green-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  Formulario almacenado!
                </p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                  @click="
                    () => {
                      success = '';
                    }
                  "
                  type="button"
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: mini/x-mark -->
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <div v-if="error" class="w-full flex justify-end items-center">
        <div
          class="mb-4 w-full max-w-sm overflow-hidden rounded-lg bg-white border border-gray-200"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <!-- Heroicon name: outline/check-circle -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6 text-red-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  {{ error }}
                </p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                  @click="
                    () => {
                      error = '';
                    }
                  "
                  type="button"
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: mini/x-mark -->
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- end notification -->
    <!-- FORM -->
    <div
      class="bg-white w-full border border-gray-200 rounded-lg overflow-hidden"
    >
      <!-- title -->
      <div class="w-full flex flex-col justify-center items-start">
        <div class="w-full px-4 pt-4 sm:px-6 sm:pt-6">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            Formulario de primera consulta
          </h3>
          <p
            v-if="this.user.roles.find(e => e.name === 'doctor')"
            class="mt-1 text-left max-w-2xl text-sm text-gray-500"
          >
            Crea o edita los datos del formulario.
          </p>
        </div>
        <form
          @submit.prevent="handleSubmit"
          class="inline-block align-middle transform transition-all w-full"
        >
          <div
            class="w-full grid grid-cols-6 gap-6 mt-4 px-4 pb-4 sm:px-6 sm:pb-6"
          >
            <!-- reason -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <label
                for="reason"
                class="block text-sm font-medium text-gray-700"
                >Contexto / Motivo consulta actual</label
              >
              <div class="mt-1 w-full">
                <textarea
                  :disabled="this.user.roles.find(e => e.name !== 'doctor')"
                  v-model="form.reason"
                  type="text"
                  name="reason"
                  id="reason"
                  :class="
                    submitted && $v.form.reason.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block h-40 resize-none border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="Escribe aquí"
                ></textarea>
              </div>
              <div
                v-if="submitted && !$v.form.reason.required"
                class="text-sm text-red-600"
              >
                El motivo es obligatorio
              </div>
            </div>
            <!-- mental_tests -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <label
                for="mental_tests"
                class="block text-sm font-medium text-gray-700"
                >Examen mental</label
              >
              <label
                for="mental_tests"
                class="block text-sm font-light text-gray-700"
                >Síntomas y signos</label
              >
              <div class="mt-1 w-full">
                <textarea
                  :disabled="this.user.roles.find(e => e.name !== 'doctor')"
                  v-model="form.mental_tests"
                  type="text"
                  name="mental_tests"
                  id="mental_tests"
                  :class="
                    submitted && $v.form.mental_tests.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block h-40 resize-none border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="Escribe aquí"
                ></textarea>
              </div>
              <div
                v-if="submitted && !$v.form.mental_tests.required"
                class="text-sm text-red-600"
              >
                El examen mental es obligatorio
              </div>
            </div>
            <!-- clinic_history -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <label
                for="clinic_history"
                class="block text-sm font-medium text-gray-700"
                >Historial Clínico Psiquiátrico</label
              >
              <label
                for="clinic_history"
                class="block text-sm font-light text-gray-700"
                >Atenciones previas psiquiatría, psicología y neurología.
                Preguntar también por hospitalizaciones psiquiátricas y no
                psiquiátricas</label
              >
              <div class="mt-1 w-full">
                <textarea
                  :disabled="this.user.roles.find(e => e.name !== 'doctor')"
                  v-model="form.clinic_history"
                  type="text"
                  name="clinic_history"
                  id="clinic_history"
                  :class="
                    submitted && $v.form.clinic_history.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block h-40 resize-none border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="Escribe aquí"
                ></textarea>
              </div>
              <div
                v-if="submitted && !$v.form.clinic_history.required"
                class="text-sm text-red-600"
              >
                El historial clínico es obligatorio
              </div>
            </div>
            <!-- background_check -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <label
                for="background_check"
                class="block text-sm font-medium text-gray-700"
                >Antecedentes Familiares Psiquiátricos</label
              >
              <label
                for="background_check"
                class="block text-sm font-light text-gray-700"
                >Psiquiátricas, psicológicas, neurológicas. Especificar nombre
                profesional tratante</label
              >
              <div class="mt-1 w-full">
                <textarea
                  :disabled="this.user.roles.find(e => e.name !== 'doctor')"
                  v-model="form.background_check"
                  type="text"
                  name="background_check"
                  id="background_check"
                  :class="
                    submitted && $v.form.background_check.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block h-40 resize-none border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="Escribe aquí"
                ></textarea>
              </div>
              <div
                v-if="submitted && !$v.form.background_check.required"
                class="text-sm text-red-600"
              >
                Los antecedentes familiares son obligatorios
              </div>
            </div>
            <!-- substance_use -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <label
                for="substance_use"
                class="block text-sm font-medium text-gray-700"
                >Consumo de sustancias</label
              >
              <label
                for="substance_use"
                class="block text-sm font-light text-gray-700"
                >Alcohol, marihuana, otras drogras, cafeína (café/mate/té
                verde)</label
              >
              <div class="mt-1 w-full">
                <textarea
                  :disabled="this.user.roles.find(e => e.name !== 'doctor')"
                  v-model="form.substance_use"
                  type="text"
                  name="substance_use"
                  id="substance_use"
                  :class="
                    submitted && $v.form.substance_use.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block h-40 resize-none border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="Escribe aquí"
                ></textarea>
              </div>
              <div
                v-if="submitted && !$v.form.substance_use.required"
                class="text-sm text-red-600"
              >
                El consumo de sustancias es obligatorio
              </div>
            </div>
            <!-- alternatives_medicine -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <label
                for="alternatives_medicine"
                class="block text-sm font-medium text-gray-700"
                >¿Ha consultado medicina alternativa?</label
              >
              <label
                for="alternatives_medicine"
                class="block text-sm font-light text-gray-700"
                >Especificar técnica y para qué malestar/enfermedad</label
              >
              <div class="mt-1 w-full">
                <textarea
                  :disabled="this.user.roles.find(e => e.name !== 'doctor')"
                  v-model="form.alternatives_medicine"
                  type="text"
                  name="alternatives_medicine"
                  id="alternatives_medicine"
                  :class="
                    submitted && $v.form.alternatives_medicine.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block h-40 resize-none border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="Escribe aquí"
                ></textarea>
              </div>
              <div
                v-if="submitted && !$v.form.alternatives_medicine.required"
                class="text-sm text-red-600"
              >
                El consumo de medicina alternativa es obligatorio
              </div>
            </div>
            <!-- suicidal_risk -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <label
                for="suicidal_risk"
                class="block text-sm font-medium text-gray-700"
                >Riesgo Suicidal</label
              >
              <label
                for="suicidal_risk"
                class="block text-sm font-light text-gray-700"
                >Sí/No. Especificar</label
              >
              <div class="mt-1 w-full">
                <textarea
                  :disabled="this.user.roles.find(e => e.name !== 'doctor')"
                  v-model="form.suicidal_risk"
                  type="text"
                  name="suicidal_risk"
                  id="suicidal_risk"
                  :class="
                    submitted && $v.form.suicidal_risk.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block h-40 resize-none border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="Escribe aquí"
                ></textarea>
              </div>
              <div
                v-if="submitted && !$v.form.suicidal_risk.required"
                class="text-sm text-red-600"
              >
                El riesgo suicidal es obligatorio
              </div>
            </div>
            <!-- DIAGNOSTICO -->
            <div
              v-if="false"
              class="w-full col-span-6 border border-gray-200 border-dashed rounded-md py-3 px-4"
            >
              <h4
                class="text-md leading-6 mb-4 font-medium text-left w-full text-gray-900"
              >
                Diagnóstico Ingreso GES
              </h4>
              <h4
                class="text-md leading-6 font-medium text-left w-full text-gray-900"
              >
                Diagnóstico CIE 10
              </h4>
              <div class="w-full grid grid-cols-1 md:grid-cols-3 mb-4 gap-2">
                <input
                  v-model="form.diagnostic_search"
                  type="text"
                  required
                  class="appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                  placeholder="Código CIE10"
                />
                <input
                  disabled
                  v-model="form.diagnostic"
                  type="text"
                  required
                  class="appearance-none col-span-2 cursor-not-allowed bg-gray-200 relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                  placeholder="GES"
                />
              </div>
              <div class="w-full mb-4 flex flex-row justify-start items-center">
                <h4
                  class="text-md leading-6 font-medium text-left w-full text-gray-900"
                >
                  ¿Es patología GES?
                </h4>
                <div class="flex flex-row justify-center">
                  <div class="mt-1 w-full flex">
                    <div class="flex items-center mr-4">
                      <button
                        @click="
                          () => {
                            form.is_ges = true;
                          }
                        "
                        type="button"
                        :class="form.is_ges ? 'bg-green-600' : 'bg-gray-200'"
                        class="h-8 w-8 rounded-md mr-2 flex justify-center items-center"
                      >
                        <svg
                          v-if="form.is_ges"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-4 h-4 text-white"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          />
                        </svg>
                      </button>
                      <span class="text-gray-600">Si</span>
                    </div>
                    <div class="flex items-center mr-4">
                      <button
                        @click="
                          () => {
                            form.is_ges = false;
                          }
                        "
                        type="button"
                        :class="!form.is_ges ? 'bg-green-600' : 'bg-gray-200'"
                        class="h-8 w-8 rounded-md mr-2 flex justify-center items-center"
                      >
                        <svg
                          v-if="!form.is_ges"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-4 h-4 text-white"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          />
                        </svg>
                      </button>
                      <span class="text-gray-600">No</span>
                    </div>
                  </div>
                </div>
              </div>
              <h4
                class="text-md leading-6 font-medium text-left w-full text-gray-900"
              >
                Problema GES
              </h4>
              <div class="w-full grid grid-cols-1 md:grid-cols-3 mb-4 gap-2">
                <input
                  v-model="form.problem_ges_search"
                  type="text"
                  required
                  class="appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                  placeholder="Código CIE10"
                />
                <input
                  disabled
                  v-model="form.problem_ges"
                  type="text"
                  required
                  class="appearance-none col-span-2 cursor-not-allowed bg-gray-200 relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                  placeholder="GES"
                />
              </div>
              <h4
                class="text-md leading-6 font-medium text-left w-full text-gray-900"
              >
                Garantía Financiera
              </h4>
              <select
                name="warranty"
                autocomplete="warranty"
                v-model="form.warranty"
                class="border-gray-300 focus:border-green-500 focus:ring-green-500 col-span-6 mb-2 block w-full py-2 px-3 disabled:text-gray-400 border bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
              >
                <option selected disabled :value="null">Seleccionar</option>
                <option
                  v-for="(warranty, warrantyIndex) in warrantys"
                  :key="warrantyIndex"
                  >{{ warranty }}</option
                >
              </select>
            </div>
            <!-- other_diagnoses -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <label
                for="other_diagnoses"
                class="block text-sm font-medium text-gray-700"
                >Otros diagnósticos médicos</label
              >
              <div class="mt-1 w-full">
                <textarea
                  :disabled="this.user.roles.find(e => e.name !== 'doctor')"
                  v-model="form.other_diagnoses"
                  type="text"
                  name="other_diagnoses"
                  id="other_diagnoses"
                  :class="
                    submitted && $v.form.other_diagnoses.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block h-40 resize-none border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="Escribe aquí"
                ></textarea>
              </div>
            </div>
            <!-- pharmacological_indications -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <label
                for="pharmacological_indications"
                class="block text-sm font-medium text-gray-700"
                >Indicaciones Farmacológicas y otras</label
              >
              <div class="mt-1 w-full">
                <textarea
                  :disabled="this.user.roles.find(e => e.name !== 'doctor')"
                  v-model="form.pharmacological_indications"
                  type="text"
                  name="pharmacological_indications"
                  id="pharmacological_indications"
                  :class="
                    submitted && $v.form.pharmacological_indications.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block h-40 resize-none border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="Escribe aquí"
                ></textarea>
              </div>
            </div>
            <!-- psychotherapy -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <label
                for="psychotherapy"
                class="block text-sm font-medium text-gray-700"
                >¿Se prescribe psicoterapia?</label
              >
              <div class="mt-1 w-full flex">
                <div class="flex items-center mr-4">
                  <button
                    :disabled="this.user.roles.find(e => e.name !== 'doctor')"
                    @click="
                      () => {
                        form.psychotherapy = true;
                      }
                    "
                    type="button"
                    :class="form.psychotherapy ? 'bg-green-600' : 'bg-gray-200'"
                    class="h-8 w-8 rounded-md mr-2 flex justify-center items-center"
                  >
                    <svg
                      v-if="form.psychotherapy"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 text-white"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>
                  </button>
                  <span class="text-gray-600">Si</span>
                </div>
                <div class="flex items-center mr-4">
                  <button
                    :disabled="this.user.roles.find(e => e.name !== 'doctor')"
                    @click="
                      () => {
                        form.psychotherapy = false;
                      }
                    "
                    type="button"
                    :class="
                      !form.psychotherapy ? 'bg-green-600' : 'bg-gray-200'
                    "
                    class="h-8 w-8 rounded-md mr-2 flex justify-center items-center"
                  >
                    <svg
                      v-if="!form.psychotherapy"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 text-white"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>
                  </button>
                  <span class="text-gray-600">No</span>
                </div>
              </div>
            </div>
            <!-- hospital -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <label
                for="hospital"
                class="block text-sm font-medium text-gray-700"
                >¿Se prescribe hospital de día?</label
              >
              <div class="mt-1 w-full flex">
                <div class="flex items-center mr-4">
                  <button
                    :disabled="this.user.roles.find(e => e.name !== 'doctor')"
                    @click="
                      () => {
                        form.hospital = true;
                      }
                    "
                    type="button"
                    :class="form.hospital ? 'bg-green-600' : 'bg-gray-200'"
                    class="h-8 w-8 rounded-md mr-2 flex justify-center items-center"
                  >
                    <svg
                      v-if="form.hospital"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 text-white"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>
                  </button>
                  <span class="text-gray-600">Si</span>
                </div>
                <div class="flex items-center mr-4">
                  <button
                    @click="
                      () => {
                        form.hospital = false;
                      }
                    "
                    type="button"
                    :class="!form.hospital ? 'bg-green-600' : 'bg-gray-200'"
                    class="h-8 w-8 rounded-md mr-2 flex justify-center items-center"
                  >
                    <svg
                      v-if="!form.hospital"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 text-white"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>
                  </button>
                  <span class="text-gray-600">No</span>
                </div>
              </div>
            </div>
            <!-- hospitalization -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <label
                for="hospitalization"
                class="block text-sm font-medium text-gray-700"
                >¿Se prescribe hospitalización?</label
              >
              <div class="mt-1 w-full flex">
                <div class="flex items-center mr-4">
                  <button
                    :disabled="this.user.roles.find(e => e.name !== 'doctor')"
                    @click="
                      () => {
                        form.hospitalization = true;
                      }
                    "
                    type="button"
                    :class="
                      form.hospitalization ? 'bg-green-600' : 'bg-gray-200'
                    "
                    class="h-8 w-8 rounded-md mr-2 flex justify-center items-center"
                  >
                    <svg
                      v-if="form.hospitalization"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 text-white"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>
                  </button>
                  <span class="text-gray-600">Si</span>
                </div>
                <div class="flex items-center mr-4">
                  <button
                    :disabled="this.user.roles.find(e => e.name !== 'doctor')"
                    @click="
                      () => {
                        form.hospitalization = false;
                      }
                    "
                    type="button"
                    :class="
                      !form.hospitalization ? 'bg-green-600' : 'bg-gray-200'
                    "
                    class="h-8 w-8 rounded-md mr-2 flex justify-center items-center"
                  >
                    <svg
                      v-if="!form.hospitalization"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4 text-white"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>
                  </button>
                  <span class="text-gray-600">No</span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="this.user.roles.find(e => e.name === 'doctor')"
            class="bg-gray-100 px-4 py-3 text-right sm:px-6"
          >
            <button
              type="submit"
              class="inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PatientService from '@/services/patientService';
import BlockService from '@/services/blockService';
import { required } from 'vuelidate/lib/validators';
import cie10 from 'cie10';

import PatientForm from '@/views/dashboard/user/patient/Form';

export default {
  name: 'Consulta',
  components: {
    'patient-form': PatientForm,
  },
  data() {
    return {
      tab: 'form',
      cie10_array: cie10('obj'),
      form: {
        reason: '',
        mental_tests: '',
        clinic_history: '',
        background_check: '',
        substance_use: '',
        alternatives_medicine: '',
        suicidal_risk: '',
        other_diagnoses: '',
        pharmacological_indications: '',
        psychotherapy: false,
        hospital: false,
        hospitalization: false,
        diagnostic_search: '',
        diagnostic: '',
        is_ges: false,
        problem_ges_search: '',
        problem_ges: '',
        warranty: '',
      },
      warrantys: [],
      submitted: false,
      success: false,
      error: '',
      patient: null,
      block: null,
      id: null,
    };
  },
  validations: {
    form: {
      reason: {
        required,
      },
      mental_tests: {
        required,
      },
      clinic_history: {
        required,
      },
      background_check: {
        required,
      },
      substance_use: {
        required,
      },
      alternatives_medicine: {
        required,
      },
      suicidal_risk: {
        required,
      },
      other_diagnoses: {},
      pharmacological_indications: {},
    },
  },
  created() {
    this.updateData();
  },
  methods: {
    getPatient() {
      PatientService.getPatientFromDoctor(
        this.block.reservation.purchase.user_id
      )
        .then(response => {
          this.block.reservation.purchase.user = response.data.patient;
          this.patient = response.data.patient;
          this.id = this.patient.id;
          if (
            this.block.reservation.purchase.user.patient &&
            this.block.reservation.purchase.user.patient.diagnostic
          ) {
            this.form = {
              reason: this.block.reservation.purchase.user.patient.diagnostic
                .reason,
              mental_tests: this.block.reservation.purchase.user.patient
                .diagnostic.mental_tests,
              clinic_history: this.block.reservation.purchase.user.patient
                .diagnostic.clinic_history,
              background_check: this.block.reservation.purchase.user.patient
                .diagnostic.background_check,
              substance_use: this.block.reservation.purchase.user.patient
                .diagnostic.substance_use,
              alternatives_medicine: this.block.reservation.purchase.user
                .patient.diagnostic.alternatives_medicine,
              suicidal_risk: this.block.reservation.purchase.user.patient
                .diagnostic.suicidal_risk,
              other_diagnoses: this.block.reservation.purchase.user.patient
                .diagnostic.other_diagnoses,
              pharmacological_indications: this.block.reservation.purchase.user
                .patient.diagnostic.pharmacological_indications,
              psychotherapy: this.block.reservation.purchase.user.patient
                .diagnostic.psychotherapy
                ? true
                : false,
              hospital: this.block.reservation.purchase.user.patient.diagnostic
                .hospital
                ? true
                : false,
              hospitalization: this.block.reservation.purchase.user.patient
                .diagnostic.hospitalization
                ? true
                : false,
              diagnostic_search: '',
              diagnostic: this.block.reservation.purchase.user.patient
                .diagnostic.diagnostic_search,
              is_ges: this.block.reservation.purchase.user.patient.diagnostic
                .is_ges
                ? true
                : false,
              problem_ges_search: '',
              problem_ges: this.block.reservation.purchase.user.patient
                .diagnostic.problem_ges,
              warranty: this.block.reservation.purchase.user.patient.diagnostic
                .warranty,
            };
          }
        })
        .catch(() => {
          this.$router.replace({ path: '/psychologist/calendar' });
        });
    },
    updateData() {
      if (this.$route.query.block) {
        BlockService.getBlock(this.$route.query.block)
          .then(response => {
            this.block = response.data.block;
            this.getPatient();
          })
          .catch(() => {
            console.log('error getting block');
          });
      } else {
        this.block = {
          reservation: {
            purchase: {
              user_id: this.$route.params.user_id,
            },
          },
        };
        this.getPatient();
      }
    },
    handleSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.user.roles.find(e => e.name === 'admin')) {
        PatientService.savePatientFormAdmin({
          id: this.id.toString(),
          reason: this.form.reason,
          mental_tests: this.form.mental_tests,
          clinic_history: this.form.clinic_history,
          background_check: this.form.background_check,
          substance_use: this.form.substance_use,
          alternatives_medicine: this.form.alternatives_medicine,
          suicidal_risk: this.form.suicidal_risk,
          other_diagnoses: this.form.other_diagnoses,
          pharmacological_indications: this.form.pharmacological_indications,
          psychotherapy: this.form.psychotherapy,
          hospital: this.form.hospital,
          hospitalization: this.form.hospitalization,
          diagnostic: this.form.diagnostic,
          is_ges: this.form.is_ges,
          problem_ges: this.form.problem_ges,
          warranty: this.form.warranty,
        })
          .then(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.success = true;
            setTimeout(() => {
              this.success = false;
            }, 10000);
          })
          .catch(err => {
            console.log(`failed to create user: ${err}`);
            this.error = 'Hubo un error al almacenar los datos.';
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setTimeout(() => {
              this.error = '';
            }, 10000);
          });
      }
      if (this.user.roles.find(e => e.name === 'doctor')) {
        PatientService.savePatientFormDoctor({
          id: this.id.toString(),
          reason: this.form.reason,
          mental_tests: this.form.mental_tests,
          clinic_history: this.form.clinic_history,
          background_check: this.form.background_check,
          substance_use: this.form.substance_use,
          alternatives_medicine: this.form.alternatives_medicine,
          suicidal_risk: this.form.suicidal_risk,
          other_diagnoses: this.form.other_diagnoses,
          pharmacological_indications: this.form.pharmacological_indications,
          psychotherapy: this.form.psychotherapy,
          hospital: this.form.hospital,
          hospitalization: this.form.hospitalization,
        })
          .then(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.success = true;
            setTimeout(() => {
              this.success = false;
            }, 10000);
          })
          .catch(err => {
            console.log(`failed to create user: ${err}`);
            this.error = 'Hubo un error al almacenar los datos.';
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setTimeout(() => {
              this.error = '';
            }, 3000);
          });
      }
    },
  },
  watch: {
    '$route.params.user_id'() {
      this.updateData();
    },
    '$route.query.block'() {
      this.updateData();
    },
    'form.diagnostic_search'() {
      this.form.diagnostic_search = this.form.diagnostic_search.toUpperCase();
      let temp = this.cie10_array[this.form.diagnostic_search];
      if (this.form.diagnostic_search && temp) {
        this.form.diagnostic = temp;
      } else {
        this.form.diagnostic = '';
      }
    },
  },
  computed: {
    ...mapGetters({
      user: ['authentication/user'],
    }),
  },
};
</script>
